<template>
	<div>
		<Navbar/>
		<!--
		<script type="application/ld+json">
		[
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "Abonnement 3 ans",
				"image": "",
				"description": "Paiement mensuel. Économisez 45 %",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=6",
					"priceCurrency": "EUR",
					"price": "2.75",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			},
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "Abonnement 2 ans",
				"image": "",
				"description": "Paiement mensuel. Économisez 41,6 %",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=5",
					"priceCurrency": "EUR",
					"price": "2.91",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			},
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "Abonnement 1 ans",
				"image": "",
				"description": "Paiement mensuel. Économisez 16,6 %",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=4",
					"priceCurrency": "EUR",
					"price": "4.16",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			},
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "Abonnement 1 mois",
				"image": "",
				"description": "Paiement mensuel.",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=3",
					"priceCurrency": "EUR",
					"price": "5",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			}
		]
		</script>
		-->
		<div :style="{'background-image': `url(${require('../assets/img/cover3.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}" class="pt-12">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8" >
				<div class="lg:text-center mb-8">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase"> VPN </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('vpn.headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
						{{ $t('vpn.headline2') }}
					</p>
				</div>
			</div>
		</div>
		<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
			<!-- component -->
			<section>
				<div class="container max-w-full mx-auto py-4 px-6">
					<div class="max-w-full md:max-w-6xl mx-auto my-3 md:px-8">
						<div class="relative block flex flex-col md:flex-row items-center">
							<div class="w-11/12 max-w-sm sm:w-3/5 border-hydro border-2 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow hover:shadow-lg md:-mr-4">
								<div class="bg-white text-black rounded-lg overflow-hidden">
									<div class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
										<h1	class="text-lg font-medium  p-3 pb-0 text-center tracking-wide">
											{{ $t('vpn.subscription') }} 3 {{ $t('vpn.years') }}
										</h1>
										<h2 class="text-4xl font-black text-gray-500 text-center">2.75€</h2>
										<p class="text-center mb-4 font-hairline text-xl">
											{{ $t('vpn.per_months') }}
										</p>
										<p class="bg-red-100 mx-8 mb-6 text-red-700 rounded-full text-center">{{ $t('vpn.save') }} 45%</p>
										<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=6" class="text-center mx-1 py-3 px-3 bg-hydro text-white rounded hover:bg-hydroactive">
											{{ $t('vpn.choose_subscription') }} 3 {{ $t('vpn.years') }}
										</a>
										<p class="text-center mt-4 text-gray-500"><span class="line-through text-red-500">180€</span> 99€ / 3 {{ $t('vpn.years') }}</p>
									</div>
									<div class="flex flex-wrap mt-3 px-6">
									</div>
								</div>
							</div>
							<div class="sm:ml-8 w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow hover:shadow-lg md:-mr-4">
								<div class="bg-white text-black rounded-lg overflow-hidden">
									<div class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
										<h1	class="text-lg font-medium  p-3 pb-0 text-center tracking-wide">
											{{ $t('vpn.subscription') }} 2 {{ $t('vpn.years') }}
										</h1>
										<h2 class="text-4xl font-black text-gray-500 text-center">2.91€</h2>
										<p class="text-center mb-4 font-hairline text-xl">
											{{ $t('vpn.per_months') }}
										</p>
										<p class="bg-gray-100 mx-8 mb-6 text-gray-700 rounded-full text-center">{{ $t('vpn.save') }} 41.6%</p>
										<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=5" class="text-center mx-1 py-3 px-3 border border-hydro text-hydro hover:text-white rounded hover:bg-hydro">
											{{ $t('vpn.choose_subscription') }} 2 {{ $t('vpn.years') }}
										</a>
										<p class="text-center mt-4 text-gray-500"><span class="line-through text-red-500">120€</span> 70€/ 2 {{ $t('vpn.years') }}</p>
									</div>
									<div class="flex flex-wrap mt-3 px-6">
									</div>
								</div>
							</div>
							<div class="sm:ml-8 w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow hover:shadow-lg md:-mr-4">
								<div class="bg-white text-black rounded-lg overflow-hidden">
									<div class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
										<h1	class="text-lg font-medium  p-3 pb-0 text-center tracking-wide">
											{{ $t('vpn.subscription') }} 1 {{ $t('vpn.years') }}
										</h1>
										<h2 class="text-4xl font-black text-gray-500 text-center">4.16€</h2>
										<p class="text-center mb-4 font-hairline text-xl">
											{{ $t('vpn.per_months') }}
										</p>
										<p class="bg-gray-100 mx-8 mb-6 text-gray-700 rounded-full text-center">{{ $t('vpn.save') }} 16.6%</p>

										<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=4" class="text-center mx-1 py-3 px-3 border border-hydro text-hydro hover:text-white rounded hover:bg-hydro">
											{{ $t('vpn.choose_subscription') }} 1 {{ $t('vpn.year') }}
										</a>
										<p class="text-center mt-4 text-gray-500"><span class="line-through text-red-500">60€</span> 50€/{{ $t('vpn.year') }}</p>
									</div>
									<div class="flex flex-wrap mt-3 px-6">
									</div>
								</div>
							</div>
							<div class="sm:ml-8 w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow hover:shadow-lg md:-mr-4">
								<div class="bg-white text-black rounded-lg overflow-hidden">
									<div class="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
										<h1	class="text-lg font-medium  p-3 pb-0 text-center tracking-wide">
											{{ $t('vpn.subscription') }} 1 {{ $t('home.month') }}
										</h1>
										<h2 class="text-4xl font-black text-gray-500 text-center">5€</h2>
										<p class="text-center mb-4 font-hairline text-xl">
											{{ $t('vpn.per_months') }}
										</p>
										<p class="bg-gray-100 mx-8 mb-6 text-gray-700 rounded-full text-center">{{ $t('vpn.save') }} 0%</p>

										<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=3" class="text-center mx-1 py-3 px-3 border border-hydro text-hydro hover:text-white rounded hover:bg-hydro">
											{{ $t('vpn.choose_subscription') }} 1 {{ $t('home.month') }}
										</a>
										<p class="text-center mt-4 text-gray-500">5€/{{ $t('home.month') }}</p>
									</div>
									<div class="flex flex-wrap mt-3 px-6">
									</div>
								</div>
							</div>
						</div>
						<p class="text-center text-gray-500 text-sm font-hairline">*{{ $t('vpn.ht') }}</p>

					</div>
				</div>
			</section>
		</div>
		<div class="mx-5">
			<ul class="md:grid md:grid-cols-3 md:col-gap-8 md:row-gap-10">
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/safe.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('vpn.anonymous') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('vpn.anonymous_desc') }}
						</p>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/remote.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('vpn.everywhere') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('vpn.everywhere_desc') }}
						</p>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/world.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('vpn.worldwide') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('vpn.worldwide_desc') }}
						</p>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/hacker.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('vpn.secure') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('vpn.secure_desc') }}
						</p>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/rocket.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('vpn.fast') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('vpn.fast_desc') }}
						</p>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<img src="../assets/img/growing.svg" class="m-auto h-32" alt="chat">
					</div>
					<div class="ml-4 text-center">
						<h5 class="text-lg leading-6 font-medium text-gray-900">{{ $t('vpn.evolution') }}</h5>
						<p class="mt-2 text-base leading-6 text-gray-500">
							{{ $t('vpn.evolution_desc') }}
						</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="bg-gray-50 mt-12">
			<Footer />
		</div>
	</div>
</template>

<script>
	import Navbar from "@/components/Navbar";
	import Footer from "@/components/Footer";
	export default {
		name: 'VPN',
      metaInfo: () => ({
        title: 'HydroSaaS',
        titleTemplate: 'VPN %s | Internet en toute sécurité',
        meta: [
          { name: 'description', content: 'Profitez d\'Internet en toute sécurité et choisissez l\'origine de votre VPN parmi une longue liste de pays disponibles. Surfez sans limites !' },
          { name: 'og:title', content: 'Internet en toute sécurité avec le VPN HydroSaaS' },
          { name: 'twitter:title', content: 'Internet en sécurité avec le VPN HydroSaaS' },
          { name: 'og:description', content: 'Profitez d\'Internet en toute sécurité et choisissez l\'origine de votre VPN parmi une longue liste de pays disponibles. Surfez sans limites !' },
          { name: 'twitter:description', content: 'Profitez d\'Internet en toute sécurité et choisissez l\'origine de votre VPN parmi une longue liste de pays disponibles. Surfez sans limites !' },
          { name: 'twitter:image', content: 'https://hydrosaas.com/vpn.png' },
          { name: 'og:image', content: 'https://hydrosaas.com/vpn.png' },
        ],
      }),
      htmlAttrs: {
        amp: true
      },
		components: {
			Footer,
			Navbar,
		},
	}
</script>
